import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, Box} from '@mui/material';
// import backgroundImage from '../assets/img/background.jpg';
import backgroundVideo from '../assets/video/background_video.mov';
import useStyles from '../styles/styles';
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {calcEst, showEst, optionsRequired} from '../common/sharedFunctions';

const Hero = (props) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const isRTL = i18n.dir();
    const navigate = useNavigate();
    const role = props.role;
    const [loggedIn, setLoggedIn] = useState(false);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if (auth.profile && auth.profile.uid) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [auth.profile]);

    return (
        <Box className={`${classes.heroBox} mb-24`} sx={{backgroundSize: 'cover'}}
             style={{direction: isRTL === 'rtl' ? 'rtl' : 'ltr'}}>
            <video autoPlay muted loop className="w-full sm:h-3/4 h-[870px] object-cover sm:object-bottom object-right-bottom absolute top-0 left-0" src={backgroundVideo}/>
            <Grid container spacing={12} className={classes.gridContainer} style={{
                position: 'relative',
                zIndex: 10
            }}>
                <Grid item xs={12} md={7} className="sm:!pt-20">
                    <span className={`${classes.titleMain} font-light text-[2.50rem] leading-tight`}>
                        {t('book_your_title')}
                    </span>
                    <Typography variant="h6" className={classes.subtitleMain}>
                        {t('about_us_content2')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        className="!rounded-3xl !font-bold"
                        sx={{width: '200px', fontSize: '16px'}}
                        onClick={(e) => {
                            e.preventDefault();
                            if (loggedIn) {
                                navigate(((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings')
                            } else {
                                navigate('/login')
                            }
                        }}
                    >
                        {role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin')) ? t('info') : t('book_your_ride_menu')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Hero;
