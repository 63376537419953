import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";
import {colors} from '../components/Theme/WebTheme';

export default function AlertDialog(props) {
    const {t} = useTranslation();
    const {open, onClose, children} = props;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{color: colors.Header_Background}}>{"Alert"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}
                        style={{backgroundColor: colors.Header_Background, color: colors.Header_Text, padding: 10,}}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
