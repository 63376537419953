import {
    FETCH_CARS,
    FETCH_CARS_SUCCESS,
    FETCH_CARS_FAILED,
    EDIT_CAR
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, set, child, remove, push } from "firebase/database";
  import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
  export const fetchCars = () => (dispatch) => {
  
    const {
        carsRef
    } = firebase;
  
    dispatch({
      type: FETCH_CARS,
      payload: null
    });

    const userInfo = store.getState().auth.profile;

    onValue(carsRef(userInfo.uid, userInfo.usertype), snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_CARS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_CARS_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_cars
        });
      }
    });
  };
  
  export const editCar = (car, method) => async (dispatch) => {
    const {
      singleUserRef,
      carAddRef, 
      carEditRef,
      carImage,
      registrationImage,
      insuranceImage,
    } = firebase;
    dispatch({
      type: EDIT_CAR,
      payload: { method, car }
    });
    if (method === 'Add') {
        push(carAddRef, car);
    } else if (method === 'Delete') {
        remove(carEditRef(car.id));
    } else if (method === 'UpdateImage') {
      await uploadBytesResumable(carImage(car.id),car.car_image,
      registrationImage(car.id),car.car_registration,
      insuranceImage(car.id),car.car_insurance);
      let image = await getDownloadURL(carImage(car.id));
      let registration = await getDownloadURL(registrationImage(car.id));
      let insurance = await getDownloadURL(insuranceImage(car.id));

      let data = car;
      data.car_image = image;
      data.car_registration = registration;
      data.car_insurance = insurance;
      set(carEditRef(car.id), data);
      if(car.active && car.driver){
        update(singleUserRef(car.driver), {
          updateAt: new Date().getTime(),
          car_image: image,
          car_registration:registration,
          car_insurance:insurance
        });
      }   
    }
     else {
        set(carEditRef(car.id),car);
    }
  }

  export const updateUserCarWithImage = (newData, blob) => async (dispatch) => {
    const {
      auth,
      carAddRef,
      singleUserRef,
      carImage,
      registrationImage,
      insuranceImage,
    } = firebase;
  
    var carId = push(carAddRef).key;
    var registrationId = push(carAddRef).key;
    var insuranceId = push(carAddRef).key
    try {
      // Upload images
      await Promise.all([
        uploadBytesResumable(carImage(carId), blob),
        uploadBytesResumable(registrationImage(registrationId), blob),
        uploadBytesResumable(insuranceImage(insuranceId), blob),
      ]);
  
      // Get download URLs
      const [carImageUrl, registrationImageUrl, insuranceImageUrl] = await Promise.all([
        getDownloadURL(carImage(carId)),
        getDownloadURL(registrationImage(registrationId)),
        getDownloadURL(insuranceImage(insuranceId)),
      ]);
  
      // Update newData with URLs
      newData.car_image = carImageUrl;
      newData.car_registration = registrationImageUrl;
      newData.car_insurance = insuranceImageUrl;
  
      // Set data in Firebase
      await set(child(carAddRef, carId), newData);
  
      // Update user if active
      if (newData.active && auth.currentUser) {
        const updateData = {
          carType: newData.carType,
          vehicleNumber: newData.vehicleNumber,
          vehicleMake: newData.vehicleMake,
          vehicleModel: newData.vehicleModel,
          color:newData.color,
          door:newData.door,
          seat: newData.seat,
          year: newData.year,
          other_info: newData.other_info,
          car_image: carImageUrl,
          car_registration: registrationImageUrl,
          car_insurance: insuranceImageUrl,
          carApproved: newData.approved,
          updateAt: new Date().getTime(),
        };
        await update(singleUserRef(auth.currentUser.uid), updateData);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      // Handle error
    }
  };
  