import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useStyles from "../../styles/styles";

const SocialMedia = () => {
    const settings = useSelector(state => state.settingsdata.settings);
    const {t, i18n} = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();

    return (
        <div className="w-full flex justify-center items-center flex-col">
            <div dir={isRTL === "rtl" ? "rtl" : "ltr"}>
                <div className="flex">
                    {settings && settings.FacebookHandle ?
                        <div>
                            <Tooltip
                                id="instagram-facebook"
                                title={t('follow_facebook')}
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{tooltip: classes.tooltip}}
                            >
                                <IconButton
                                    href={settings.FacebookHandle}
                                    target="_blank"
                                    className={classes.navLink}
                                    style={{color: '#fff'}}
                                >
                                    <i className={classes.socialIcons + " fab fa-facebook"}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : null}
                    {settings && settings.TwitterHandle ?
                        <div>
                            <Tooltip
                                id="instagram-twitter"
                                title={t('follow_twitter')}
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{tooltip: classes.tooltip}}
                            >
                                <IconButton
                                    href={settings.TwitterHandle}
                                    target="_blank"
                                    className={classes.navLink}
                                >
                                    <svg className="w-6 fill-white" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                                    </svg>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : null}
                    {/*Youtube*/}
                    {settings && settings.YoutubeHandle ?
                        <div>
                            <Tooltip
                                title={t('YoutubeHandle')}
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{tooltip: classes.tooltip}}
                            >
                                <IconButton
                                    href={settings.YoutubeHandle}
                                    target="_blank"
                                    className={classes.navLink}
                                    style={{color: '#fff'}}
                                >
                                    <i className={classes.socialIcons + " fab fa-youtube"}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : null}
                    {settings && settings.InstagramHandle ?
                        <div>
                            <Tooltip
                                title={t('follow_instagram')}
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{tooltip: classes.tooltip}}
                            >
                                <IconButton
                                    href={settings.InstagramHandle}
                                    target="_blank"
                                    className={classes.navLink}
                                    style={{color: '#fff'}}
                                >
                                    <i className={classes.socialIcons + " fab fa-instagram"}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : null}
                    {/*Pinterest*/}
                    {settings && settings.PinterestHandle ?
                        <div>
                            <Tooltip
                                title={t('PinterestHandle')}
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{tooltip: classes.tooltip}}
                            >
                                <IconButton
                                    href={settings.PinterestHandle}
                                    target="_blank"
                                    className={classes.navLink}
                                    style={{color: '#fff'}}
                                >
                                    <i className={classes.socialIcons + " fab fa-pinterest"}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : null}
                    {/*LinkedIn*/}
                    {settings && settings.LinkedInHandle ?
                        <div>
                            <Tooltip
                                title={t('LinkedInHandle')}
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{tooltip: classes.tooltip}}
                            >
                                <IconButton
                                    href={settings.LinkedInHandle}
                                    target="_blank"
                                    className={classes.navLink}
                                    style={{color: '#fff'}}
                                >
                                    <i className={classes.socialIcons + " fab fa-linkedin"}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : null}
                    {/*LinkedIn*/}
                    {settings && settings.BlogHandle ?
                        <div>
                            <Tooltip
                                title={t('BlogHandle')}
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{tooltip: classes.tooltip}}
                            >
                                <IconButton
                                    href={settings.BlogHandle}
                                    target="_blank"
                                    className={classes.navLink}
                                >
                                    <svg className="w-6 fill-white" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M192 32c0 17.7 14.3 32 32 32c123.7 0 224 100.3 224 224c0 17.7 14.3 32 32 32s32-14.3 32-32C512 128.9 383.1 0 224 0c-17.7 0-32 14.3-32 32zm0 96c0 17.7 14.3 32 32 32c70.7 0 128 57.3 128 128c0 17.7 14.3 32 32 32s32-14.3 32-32c0-106-86-192-192-192c-17.7 0-32 14.3-32 32zM96 144c0-26.5-21.5-48-48-48S0 117.5 0 144V368c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144H128v96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48s-48-21.5-48-48V144z"/>
                                    </svg>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : null}

                </div>
            </div>
        </div>
    );
}

export default SocialMedia;
