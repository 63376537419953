import React from "react";
import classNames from "classnames";
import {makeStyles} from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import {useTranslation} from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import {useSelector} from "react-redux";
import ListItem from "@mui/material/ListItem";
import IconButton from '@mui/material/IconButton';
import {colors} from "../components/Theme/WebTheme";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function ContactUs(props) {
    const {t, i18n} = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    const {...rest} = props;
    const settings = useSelector(state => state.settingsdata.settings);
    return (
        <div style={{margin: '-8px'}} className="bg-blue-950">
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/image_leaving.jpg").default}/>
            <div className={classNames(classes.main, classes.mainRaised)}>

                <div className="px-8">
                    <br/>
                    <h2 style={{
                        textAlign: isRTL === 'rtl' ? 'right' : 'left',
                        position: "relative",
                        marginTop: "30px",
                        minHeight: "32px",
                        color: colors.Header_Background,
                        textDecoration: "none",
                        [isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",
                        wordBreak: "break-word"
                    }}>{t('contact_us')}</h2>
                    <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{
                        color: colors.Header_Background,
                        fontSize: 20,
                        fontWeight: 'bold',
                        marginTop: 30
                    }}>{settings.CompanyName}</p>
                    <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}
                       style={{color: 'black', fontSize: 16}}>{settings.CompanyAddress}</p>
                    {settings && settings.contact_email ?
                        <p><span
                            className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{t('email')}: </span>
                            <a href={"mailto:" + settings.contact_email}
                               className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{settings.contact_email}</a>
                        </p>
                        : null}
                    {settings && settings.CompanyPhone ?
                        <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{t('phone')}: {settings.CompanyPhone}</p>
                        : null}
                    <div className="w-full flex justify-center items-center flex-col">
                        <p className={`${isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} text-2xl font-bold !text-blue-950`}
                           style={{marginTop: 10}}>Follow Us</p>
                        <div dir={isRTL === "rtl" ? "rtl" : "ltr"}>
                            <div className="flex">
                                {settings && settings.FacebookHandle ?
                                    <div>
                                        <Tooltip
                                            id="instagram-facebook"
                                            title={t('follow_facebook')}
                                            placement={window.innerWidth > 959 ? "top" : "left"}
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <IconButton
                                                href={settings.FacebookHandle}
                                                target="_blank"
                                                className={classes.navLink}
                                                style={{color: '#3b5998'}}
                                            >
                                                <i className={classes.socialIcons + " fab fa-facebook"}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    : null}
                                {settings && settings.TwitterHandle ?
                                    <div>
                                        <Tooltip
                                            id="instagram-twitter"
                                            title={t('follow_twitter')}
                                            placement={window.innerWidth > 959 ? "top" : "left"}
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <IconButton
                                                href={settings.TwitterHandle}
                                                target="_blank"
                                                className={classes.navLink}
                                                style={{color: '#00acee'}}
                                            >
                                                <svg className="w-6" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 512 512">
                                                    <path
                                                        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                                                </svg>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    : null}
                                {/*Youtube*/}
                                {settings && settings.YoutubeHandle ?
                                    <div>
                                        <Tooltip
                                            title={t('YoutubeHandle')}
                                            placement={window.innerWidth > 959 ? "top" : "left"}
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <IconButton
                                                href={settings.YoutubeHandle}
                                                target="_blank"
                                                className={classes.navLink}
                                                style={{color: '#e10a0a'}}
                                            >
                                                <i className={classes.socialIcons + " fab fa-youtube"}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    : null}
                                {settings && settings.InstagramHandle ?
                                    <div>
                                        <Tooltip
                                            title={t('follow_instagram')}
                                            placement={window.innerWidth > 959 ? "top" : "left"}
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <IconButton
                                                href={settings.InstagramHandle}
                                                target="_blank"
                                                className={classes.navLink}
                                                style={{color: '#f44e55'}}
                                            >
                                                <i className={classes.socialIcons + " fab fa-instagram"}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    : null}
                                {/*Pinterest*/}
                                {settings && settings.PinterestHandle ?
                                    <div>
                                        <Tooltip
                                            title={t('PinterestHandle')}
                                            placement={window.innerWidth > 959 ? "top" : "left"}
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <IconButton
                                                href={settings.PinterestHandle}
                                                target="_blank"
                                                className={classes.navLink}
                                                style={{color: '#e10a0a'}}
                                            >
                                                <i className={classes.socialIcons + " fab fa-pinterest"}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    : null}
                                {/*LinkedIn*/}
                                {settings && settings.LinkedInHandle ?
                                    <div>
                                        <Tooltip
                                            title={t('LinkedInHandle')}
                                            placement={window.innerWidth > 959 ? "top" : "left"}
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <IconButton
                                                href={settings.LinkedInHandle}
                                                target="_blank"
                                                className={classes.navLink}
                                                style={{color: '#3871a1'}}
                                            >
                                                <i className={classes.socialIcons + " fab fa-linkedin"}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    : null}
                                {/*LinkedIn*/}
                                {settings && settings.BlogHandle ?
                                    <div>
                                        <Tooltip
                                            title={t('BlogHandle')}
                                            placement={window.innerWidth > 959 ? "top" : "left"}
                                            classes={{tooltip: classes.tooltip}}
                                        >
                                            <IconButton
                                                href={settings.BlogHandle}
                                                target="_blank"
                                                className={classes.navLink}
                                            >
                                                <svg className="w-6 fill-amber-600" xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 512 512">
                                                    <path
                                                        d="M192 32c0 17.7 14.3 32 32 32c123.7 0 224 100.3 224 224c0 17.7 14.3 32 32 32s32-14.3 32-32C512 128.9 383.1 0 224 0c-17.7 0-32 14.3-32 32zm0 96c0 17.7 14.3 32 32 32c70.7 0 128 57.3 128 128c0 17.7 14.3 32 32 32s32-14.3 32-32c0-106-86-192-192-192c-17.7 0-32 14.3-32 32zM96 144c0-26.5-21.5-48-48-48S0 117.5 0 144V368c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144H128v96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48s-48-21.5-48-48V144z"/>
                                                </svg>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    : null}

                            </div>
                        </div>
                    </div>

                    <br/>
                </div>
            </div>
            <Footer className="text-white" poweredByClassName={'text-white'} companyWebsiteClassName={'!text-white'}/>
        </div>
    );
}
