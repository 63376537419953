import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import {makeStyles} from '@mui/styles';
import {useDispatch, useSelector} from "react-redux";
import Grid from '@mui/material/Grid';
import {api} from 'common';
import {useTranslation} from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import Button from "components/CustomButtons/Button.js";
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    }, container: {
        zIndex: "12", color: colors.WHITE, alignContent: 'center', width: '70%'
    }, container1: {
        backgroundColor: colors.WHITE,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        padding: '30px',
        width: '100%',
        top: "19px",
        boxShadow: "4px 4px 6px #9E9E9E"
    }, gridcontainer: {
        alignContent: 'center'
    }, items: {
        margin: 0, width: '100%'
    }, avatar: {
        margin: theme.spacing(1), backgroundColor: theme.palette.secondary.main, width: 192, height: 192
    }, form: {
        width: '100%',
        marginTop: theme.spacing(1),
        backgroundColor: colors.SECONDARY,
        alignContent: 'center',
        borderRadius: "8px",
    }, submit: {
        margin: theme.spacing(3, 0, 2),
    }, rootRtl: {
        "& label": {
            right: 10, left: "auto", paddingRight: 20
        }, "& legend": {
            textAlign: "right", marginRight: 20
        }
    }, rootRtl_1: {
        "& label": {
            right: 15, left: "auto", paddingRight: 25
        }, "& legend": {
            textAlign: "right", marginRight: 25
        }
    }, rootRtl_2: {
        "& label": {
            right: 10, left: "auto", paddingRight: 12
        }, "& legend": {
            textAlign: "right", marginRight: 25
        }
    }, right: {
        "& legend": {
            marginRight: 30
        },
    }, rightStorelink: {
        "& legend": {
            marginRight: 25
        },
    }, title: {
        color: colors.WHITE, marginBottom: '15px', paddingTop: '15px', paddingLeft: '15px', fontSize: '20px'
    }, buttonStyle: {
        marginTop: 10,
        width: '100%',
        height: 40,
        borderRadius: "30px",
        backgroundColor: colors.MAIN_COLOR,
        color: colors.WHITE
    }
}));

const WebhookSettings = (props) => {
    const {t, i18n} = useTranslation();
    const isRTL = i18n.dir();
    const dispatch = useDispatch();
    const {
        editSettings
    } = api;
    const settings = useSelector(state => state.settingsdata.settings);
    const classes = useStyles();
    const [data, setData] = useState();

    useEffect(() => {
        if (settings) {
            if (!settings.webhooks) {
                settings.webhooks = [
                    {
                        url: ''
                    }
                ]
            }
            setData(settings);
        }
    }, [settings]);

    const handleInputChange = (value, param, index) => {
        setData((prevState) => {
            prevState.webhooks[index].url = value;
            return {
                ...prevState
            }
        })
    }

    const handleAddWebhook = () => {
        setData(() => {
            const webhooks = data.webhooks;
            webhooks.push({
                url: ''
            })
            return {
                ...data, webhooks
            }
        })
    }

    const handleRemoveWebhook = (index) => {
        setData((prevState) => {
            const webhooks = prevState.webhooks;
            webhooks.splice(index, 1);
            return {
                ...prevState, webhooks
            }
        })
    }

    const handleSubmit = (e) => {
        if (settings.AllowCriticalEditsAdmin) {
            dispatch(editSettings(data));
        } else {
            alert(t('demo_mode'));
        }
    }


    return (<form className={classes.form}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12}>
                <Typography component="h1" variant="h5" className={classes.title}
                            style={{textAlign: isRTL === 'rtl' ? 'right' : 'left'}}>
                    {t('webhookSettings')}
                </Typography>
            </Grid>
            <div className={classes.container1}>
                <Grid container spacing={2} style={{direction: isRTL === 'rtl' ? 'rtl' : 'ltr'}}>
                    <Grid container>
                        {data?.webhooks?.length ? data.webhooks.map((item, index) => {
                            return <Grid container key={index}>
                                <Grid item xs={data?.webhooks?.length > 1 ? 10 : 12}
                                      sm={data?.webhooks?.length > 1 ? 10 : 12} md={10}
                                      lg={data?.webhooks?.length > 1 ? 10 : 12}>
                                    <TextField
                                        className={isRTL === "rtl" ? classes.rootRtl : null}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size={"small"}
                                        label={t('webhookUrl')}
                                        onChange={(e) => {
                                            handleInputChange(e.target.value, 'url', index)
                                        }}
                                        value={settings.AllowCriticalEditsAdmin ? item.url : "Hidden for Demo"}
                                    />
                                </Grid>
                                {data?.webhooks?.length > 1 ? <Grid item xs={2} sm={2} md={2} lg={2} style={{
                                    display: "flex", justifyContent: "center", alignItems: "center"
                                }}>
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            handleRemoveWebhook(index)
                                        }}
                                        variant="contained"
                                        color="danger"
                                        className={classes.buttonStyle}
                                    >
                                        - {t('remove')}
                                    </Button>
                                </Grid> : null}

                            </Grid>
                        }) : <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    className={isRTL === "rtl" ? classes.rootRtl : null}
                                    variant="outlined"
                                    margin="normal"
                                    size={"small"}
                                    fullWidth
                                    label={t('webhookUrl')}
                                    onChange={(e) => {
                                        handleInputChange(e.target.value, 'url', 0)
                                    }}
                                    value={settings.AllowCriticalEditsAdmin ? data?.[0]?.url : "Hidden for Demo"}
                                />
                            </Grid>
                        </Grid>}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                                size="sm"
                                onClick={handleAddWebhook}
                                variant="contained"
                                color="success"
                                className={classes.buttonStyle}
                            >
                               + {t('addMore')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                                size="sm"
                                onClick={handleSubmit}
                                variant="contained"
                                color="secondaryButton"
                                className={classes.buttonStyle}
                            >
                                {t('submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    </form>);

}

export default WebhookSettings;
